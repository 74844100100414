import React, { useEffect } from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
// import NewRegistrationForm from '../containers/NewRegistration/NewRegistrationForm';
import CCM from '../components/CCM';
import ChangeProfileEmail from '../containers/Profile/ChangeEmail';
import Faq from '../containers/Faq';
import ForgotPassword from '../containers/ForgotPassword';
import Home from '../containers/Home';
import Landing from '../containers/Landing';
import NewRegistration from '../containers/NewRegistration';
import NewRegistrationRegister from '../containers/NewRegistration/NewRegistrationRegister';
import PageNotFound from '../containers/PageNotFound';
import PrivacyPolicy from '../containers/PrivacyPolicy';
import PrivateRoute from './PrivateRoute';
import ResetPassword from '../containers/ResetPassword';
import SignIn from '../containers/SignIn';
import TermsOfUse from '../containers/TermsOfUse';
import UnsubRoute from '../containers/UnsubRoute';
import UserProfile from '../containers/Profile/userProfile.jsx';
import ChangeProfilePassword from '../containers/Profile/ChangePassword';
import ContactUs from '../containers/ContactUs';
import MicroSurveys from '../containers/MicroSurveys/index.jsx';
import { logger } from '../utils/logger.js';
import EmailUnsubscribe from '../containers/EmailUnsubscribe';
import Waitlist from '../containers/Waitlist/index.tsx';
import NewRegistrationMobilePromotionBanner from '../containers/NewRegistration/NewRegistrationMobilePromotionBanner/index.tsx';
import DeleteProfile from '../containers/Profile/DeleteProfile/index.tsx';

const Routes = () => {
  const location = useLocation();

  useEffect(() => {
    logger.logPageView(location?.pathname ?? '');
  }, [location]);

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={location?.pathname.slice(0, -1)} />
      <Route exact path="/" component={Landing} />
      <Route
        exact
        path="/activities/weekly-quests/:surveyId"
        component={MicroSurveys}
      />
      <PrivateRoute
        path={['/home', '/activities', '/history', '/rewards']}
        component={Home}
      />
      <PrivateRoute exact path="/profile" component={UserProfile} />
      <PrivateRoute exact path="/profile/delete" component={DeleteProfile} />
      <PrivateRoute
        exact
        path="/profile/change-email"
        component={ChangeProfileEmail}
      />
      <PrivateRoute
        exact
        path="/profile/change-password"
        component={ChangeProfilePassword}
      />
      <PrivateRoute path="/faq" component={Faq} />
      <PrivateRoute path="/terms-of-use" component={TermsOfUse} />
      <PrivateRoute path="/unsubscribe" component={UnsubRoute} />
      <PrivateRoute path="/privacy-policy" component={PrivacyPolicy} />
      <PrivateRoute path="/ccm" component={CCM} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/waitlist" component={Waitlist} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/login" component={SignIn} />
      <Route exact path="/new-registration-form" component={NewRegistration} />
      <Route
        exact
        path="/new-registration-form/register"
        component={NewRegistrationRegister}
      />
      <Route
        exact
        path="/new-registration-form/download-app"
        component={NewRegistrationMobilePromotionBanner}
      />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/email/unsubscribe" component={EmailUnsubscribe} />
      <Route component={PageNotFound} />
      <Route />
    </Switch>
  );
};

export default Routes;
