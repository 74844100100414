import { useState } from 'react';

type Props = {
  initialValue?: boolean;
};

const useModal = ({ initialValue = false }: Props = {}) => {
  const [isOpen, setIsOpen] = useState(initialValue);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return { isOpen, openModal, closeModal };
};

export default useModal;
