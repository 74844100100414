import React, { useEffect, useState } from 'react';
import API from '../../constants/common';
import CSBreadcrumbs from '../../components/CSBreadcrumbs';
import { ChevronLeft as ChevronLeftIcon } from '@livingdesign/icons';
import HomeFooter from '../../components/Footer/HomeFooter';
import ProfileAccordian from './ProfileAccordian';
import SnackBanner from '../../components/SnackBanner/index.jsx';
import UserProfilePic from './UserProfilePic/UserAvatar';
import analytics from '../../utils/analytics';
import api from '../../constants/api';
import apiCall from '../../utils/api';
import styles from './styles';
import { API_STATUS_CODE } from '../../constants/common';
import { PAGES, PROFILE_ACTIVITY_METRICS } from '../../constants/analytics';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { TOAST_MSGS } from '../../constants/common';
import {
  formatProfileStateData,
  formatDataPutAPI,
  parseActivityValue,
  findState
} from './utils';
import {
  APIConstants,
  profileHeaders,
  zipValidateFail,
  unlimitedActivityFreq,
  zipStateNotMatch
} from './constanst';
import { Link, useHistory } from 'react-router-dom';
import { useWindowSize } from '../../hooks';
import CircularLoader from '../../components/CircularLoader';
import { handleLocalStorage, handleSessionStorage } from '../../utils/common';
import ReviewInformationDialog from './ReviewInformationDialog';
import { logger } from '../../utils/logger.js';

const UserProfile = () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const { width } = useWindowSize();
  const history = useHistory();

  const handleBackClick = () => {
    history.push('/home', 'backFromProfile');
  };

  const titleText = 'Profile';
  const titleIcon = (
    <div className={classes.headerIcon}>
      <ChevronLeftIcon size="medium" />
    </div>
  );
  const breadcrumbs = [
    {
      titleText,
      titleIcon,
      onClickAction: handleBackClick
    }
  ];

  const [snackBanner, setSnackBanner] = useState({
    displaySnackBanner: false,
    snackBannerMessage: ''
  });
  const [accountPrefData, setAccountPrefData] = useState();
  const [actPreferenceData, setActPreferenceData] = useState();
  const [activity, setActivity] = useState({
    data: null,
    isLoading: false
  });
  const [callActFreqAPI, setCallActFreqAPI] = useState(true);
  const [callProfileApi, setCallProfileApi] = useState(true);
  const [householdData, setHouseholdData] = useState();
  const [personalData, setPersonalData] = useState();
  const [profileData, setProfileData] = useState({
    data: null,
    isLoading: false
  });
  const [shoppingData, setShoppingData] = useState();
  const [reviewProfileInfoDialog, setReviewProfileInfoDialog] = useState({
    open: false,
    loading: false
  });
  const [recordInteractionMetrics, setRecordInteractionMetrics] =
    useState(false);

  const openSnackBanner = (message) => {
    setSnackBanner({
      displaySnackBanner: true,
      snackBannerMessage: message
    });
  };

  const handleSnackBannerClose = () => {
    setSnackBanner(false);
  };

  const saveProfileDetail = (payload, isAddress = false) => {
    apiCall(api.profile, API.METHOD.PUT, payload, false).then((response) => {
      setCallProfileApi(true);
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        if (recordInteractionMetrics) {
          logger.analytics('profile_update_dr', {
            detail: payload?.[0]?.detailName ?? 'unknown'
          });
        } else {
          logger.analytics('profile_update', {
            detail: payload?.[0]?.detailName ?? 'unknown'
          });
        }
        openSnackBanner(
          isAddress
            ? TOAST_MSGS.address_info_update
            : TOAST_MSGS.profile_info_update
        );
      } else {
        openSnackBanner(response?.error?.message || 'Failed to save profile');
      }
    });
  };

  useEffect(() => {
    if (
      history.location?.state?.prevPath === 'signIn' &&
      history.location?.state?.action === 'openSnack'
    ) {
      openSnackBanner(history.location?.state?.snackMessage);
    }
  }, []);

  useEffect(() => {
    let alternateActivityData;

    try {
      alternateActivityData = JSON.parse(
        handleSessionStorage.get('alternateActivity')
      );
    } catch (error) {
      alternateActivityData = { taskId: -1 };
      logger.error('Failed to parse alternateActivity', {
        error,
        alternateActivity: handleSessionStorage.get('alternateActivity'),
        page: 'userProfile'
      });
    }

    const taskId = alternateActivityData?.taskId || -1;

    const acceptedProfileReview = handleSessionStorage.get(
      'acceptedProfileReview'
    );

    // Profile review not yet accepted or declined and task ID is not an invalid value, display dialog
    if (taskId > 0 && acceptedProfileReview === 'false') {
      setRecordInteractionMetrics(true);
      setReviewProfileInfoDialog({ open: true, loading: false });

      logger.analytics('profileReviewScreenPopup', {
        page: PROFILE_ACTIVITY_METRICS.PAGE,
        metric: PROFILE_ACTIVITY_METRICS.METRIC,
        event: PROFILE_ACTIVITY_METRICS.PROFILE_REVIEW_POPUP
      });
    }

    return () => {
      setRecordInteractionMetrics(false);
    };
  }, []);

  useEffect(() => {
    if (callProfileApi) {
      try {
        setProfileData({ ...profileData, isLoading: true });
        apiCall(api.profile, API.METHOD.GET, {}, false).then((response) => {
          setProfileData({ ...profileData, isLoading: false });
          if (response?.error) {
            openSnackBanner(
              `Error status:  ${response.error.status} \n Error: ${response.error.error}`
            );
          }

          if (response.data) {
            setProfileData({ ...profileData, data: response.data.profile });

            const PROFILE_DATA = response.data.profile;

            const profilePersonalData = PROFILE_DATA?.find(
              (item) => item.type === 'personal'
            )?.profileTypeDetailList;

            profilePersonalData
              ? setPersonalData(formatProfileStateData(profilePersonalData))
              : setPersonalData('');

            const profileHouseholdData = PROFILE_DATA?.find(
              (item) => item.type === 'household'
            )?.profileTypeDetailList;
            profileHouseholdData
              ? setHouseholdData(formatProfileStateData(profileHouseholdData))
              : setHouseholdData('');

            const profileShoppingData = PROFILE_DATA?.find(
              (item) => item.type === 'shopping'
            )?.profileTypeDetailList;
            profileShoppingData
              ? setShoppingData(formatProfileStateData(profileShoppingData))
              : setShoppingData('');

            const profileAccountData = PROFILE_DATA?.find(
              (item) => item.type === 'accountpreferences'
            )?.profileTypeDetailList;
            profileAccountData
              ? setAccountPrefData(formatProfileStateData(profileAccountData))
              : setAccountPrefData('');

            const profileActPreferenceData = PROFILE_DATA?.find(
              (item) => item.type === 'activitypreference'
            )?.profileTypeDetailList;
            profileActPreferenceData
              ? setActPreferenceData(
                  formatProfileStateData(profileActPreferenceData)
                )
              : setActPreferenceData('');
          }
        });
        setCallProfileApi(false);
      } catch (error) {
        console.error(
          `Error occurred while calling GET API or setting state. Error Details : \n\n\n  ${error.stack} \n\n\n`
        );
        openSnackBanner(
          ` An error occurred while retrieving data. Error details ${error}`
        );
      }
    }
  }, [callProfileApi]);

  useEffect(() => {
    if (callActFreqAPI) {
      try {
        setActivity({ ...activity, isLoading: true });
        apiCall(api.activityPref, API.METHOD.GET, {}, false).then(
          (response) => {
            setActivity({ ...activity, isLoading: false });
            if (response?.error) {
              openSnackBanner(response?.error?.message);
            }

            if (response?.status === API_STATUS_CODE.SUCCESS) {
              const activityFreqApiResponse =
                response?.data?.activityPreferences[0];
              setActivity({ ...activity, data: activityFreqApiResponse });
            }
          }
        );
      } catch (error) {
        console.error(
          `Error occurred while calling GET API or setting state. Error Details : \n\n\n  ${error.stack} \n\n\n`
        );
        openSnackBanner(
          ` An error occurred while retrieving data. Error details ${error}`
        );
      }

      setCallActFreqAPI(false);

      analytics.trackPageView(PAGES.USER_PROFILE);
    }
  }, [callActFreqAPI]);

  const saveDetailHandler = (newValue, profileDetailsData) => {
    setProfileData({ ...profileData, isLoading: true });
    try {
      if (profileDetailsData === APIConstants.detailNames.address) {
        apiCall(
          `${api.profileZipValidate}?zip=${newValue.panelist_zip}`,
          API.METHOD.GET,
          {},
          false
        ).then((response) => {
          if (response?.error) {
            openSnackBanner(
              `Error occurred: ${response?.error.error ?? zipValidateFail}`
            );
            setProfileData({ ...profileData, isLoading: false });
          }
          if (response?.status === API_STATUS_CODE.SUCCESS) {
            const responseData = response?.data;
            const { state, message } = responseData;

            if (!state) {
              openSnackBanner(message);
              setProfileData({ ...profileData, isLoading: false });
            }

            if (
              state &&
              findState(state).abbreviation !== newValue?.panelist_st
            ) {
              // check if the zip and state match
              openSnackBanner(zipStateNotMatch);
              setProfileData({ ...profileData, isLoading: false });
            } else {
              const payload = [
                {
                  detailName: APIConstants.detailNames.address,
                  selectedList: formatDataPutAPI(JSON.stringify(newValue))
                }
              ];
              saveProfileDetail(payload, true);
            }
          }
        });
      } else {
        const selectedData = formatDataPutAPI(newValue);
        const payload = [
          {
            detailName: profileDetailsData,
            selectedList: selectedData
          }
        ];
        saveProfileDetail(payload);
      }
    } catch (error) {
      logger.error(
        `Error occurred while calling put API or updating state. Error Details : \n\n\n  ${error.stack} \n\n\n`
      );
      openSnackBanner(
        ` An error occurred while setting data. Error details ${error}`
      );
    }
  };

  const saveActivityFrequencyData = (data) => {
    try {
      const { frequencyValueToSave, periodValueToSave } = data;

      const frequencyValue =
        frequencyValueToSave === unlimitedActivityFreq
          ? 0
          : parseActivityValue(frequencyValueToSave);
      const periodValue =
        frequencyValueToSave === unlimitedActivityFreq
          ? 'None'
          : periodValueToSave;

      const payload = {
        activityPreferences: [
          {
            id: 1,
            frequency: frequencyValue,
            period: periodValue
          }
        ]
      };

      apiCall(api.activityPref, API.METHOD.PUT, payload, false).then(
        (response) => {
          setCallActFreqAPI(true);
          if (response?.error) {
            openSnackBanner(response?.error?.message);
          }

          if (response?.status === API_STATUS_CODE.SUCCESS) {
            openSnackBanner(TOAST_MSGS.activity_pref_update);
          }
        }
      );
    } catch (error) {
      logger.error(
        `Error occurred while calling put API or updating state. Error Details : \n\n\n  ${error.stack} \n\n\n`
      );
      openSnackBanner(
        ` An error occurred while setting data. Error details ${error}`
      );
    }
  };

  const unsubscribeWrapper = (
    <Grid item className={classes.unsubscribeWrapper}>
      <Link to="/profile/delete">
        <Button
          id="unsubscribe"
          className={classes.unsubscribe}
          variant="outlined"
          color="primary"
        >
          Delete account
        </Button>
      </Link>
    </Grid>
  );

  const clearProfileReviewState = () => {
    handleSessionStorage.remove('acceptedProfileReview');
    handleSessionStorage.remove('alternateActivity');
    setReviewProfileInfoDialog({ open: false, loading: false });
  };

  const reviewProfileAgreeHandler = () => {
    if (recordInteractionMetrics) {
      logger.analytics('reviewProfileClick', {
        page: PROFILE_ACTIVITY_METRICS.PAGE,
        metric: PROFILE_ACTIVITY_METRICS.METRIC,
        event: PROFILE_ACTIVITY_METRICS.REVIEW_PROFILE_BTN_CLICK
      });
    }

    const panelistHash = handleLocalStorage.get('panelistId');

    let alternateActivityData;

    try {
      alternateActivityData = JSON.parse(
        handleSessionStorage.get('alternateActivity')
      );
    } catch (error) {
      alternateActivityData = { taskId: -1 };
      logger.error('Failed to parse alternateActivity', {
        error,
        alternateActivityData: handleSessionStorage.get('alternateActivity'),
        page: 'userProfile'
      });
    }

    const taskId = alternateActivityData?.taskId;

    setReviewProfileInfoDialog((prev) => ({ ...prev, loading: true }));
    apiCall(
      `${api.reviewProfile(taskId)}?panelistId=${panelistHash}`,
      API.METHOD.POST,
      {},
      false
    )
      .then((response) => {
        if (response?.error) {
          openSnackBanner(TOAST_MSGS.generic_error);
          setRecordInteractionMetrics(false);
          clearProfileReviewState();
        }
        if (response?.status === API_STATUS_CODE.SUCCESS) {
          clearProfileReviewState();
        }
      })
      .finally(() => {
        setReviewProfileInfoDialog((prev) => ({ ...prev, loading: false }));
      });
  };

  const onMaybeLaterClickHandler = () => {
    clearProfileReviewState();
    history.push('/home', 'routedFromReviewProfileMaybeLater');
  };

  const accoridanToggleHandler = ({ event, expanded, profileType }) => {
    if (
      (profileType === profileHeaders.personal ||
        profileType === profileHeaders.household ||
        profileType === profileHeaders.shopping) &&
      !!expanded &&
      !!recordInteractionMetrics
    ) {
      logger.analytics('profileHeaderExpanded', {
        page: PROFILE_ACTIVITY_METRICS.PAGE,
        metric: PROFILE_ACTIVITY_METRICS.METRIC,
        event: PROFILE_ACTIVITY_METRICS.PROFILE_HEADER_EXPANDED,
        profileType: profileType
      });
    }
  };

  return (
    <>
      <Grid container className={classes.profileHead}>
        <ReviewInformationDialog
          open={reviewProfileInfoDialog.open}
          onClose={clearProfileReviewState}
          onClickReviewProfile={reviewProfileAgreeHandler}
          onClickMaybeLater={onMaybeLaterClickHandler}
          loading={reviewProfileInfoDialog.loading}
        />
        <CSBreadcrumbs breadcrumbs={breadcrumbs} />
      </Grid>
      <div className={classes.root}>
        <SnackBanner
          isSnackbarOpen={snackBanner.displaySnackBanner}
          snackbarMessage={snackBanner.snackBannerMessage}
          onSnackBarClose={handleSnackBannerClose}
        />
        <Grid container className={classes.profile} direction="column">
          <Grid container justifyContent="center">
            <Grid item>
              <UserProfilePic />
            </Grid>
          </Grid>
          {profileData?.data ? (
            <Grid container className={classes.accordionWrapper}>
              <Grid item className={classes.accordion}>
                <div>
                  {personalData && (
                    <ProfileAccordian
                      key={profileHeaders.personal}
                      detailTitle={profileHeaders.personal}
                      profileType="personal"
                      displayValues={personalData}
                      profileDetailsData={
                        profileData?.data?.find(
                          (item) => item.type === 'personal'
                        )?.profileTypeDetailList
                      }
                      updateProfileState={saveDetailHandler}
                      isProfileDataLoading={profileData.isLoading}
                      onAccordianExpansionToggle={accoridanToggleHandler}
                    />
                  )}
                  {householdData && (
                    <ProfileAccordian
                      key={profileHeaders.household}
                      detailTitle={profileHeaders.household}
                      profileType="household"
                      displayValues={householdData}
                      profileDetailsData={
                        profileData?.data?.find(
                          (item) => item.type === 'household'
                        )?.profileTypeDetailList
                      }
                      updateProfileState={saveDetailHandler}
                      isProfileDataLoading={profileData.isLoading}
                      onAccordianExpansionToggle={accoridanToggleHandler}
                    />
                  )}
                  {shoppingData && (
                    <ProfileAccordian
                      key={profileHeaders.shopping}
                      detailTitle={profileHeaders.shopping}
                      profileType="shopping"
                      displayValues={shoppingData}
                      profileDetailsData={
                        profileData?.data?.find(
                          (item) => item.type === 'shopping'
                        )?.profileTypeDetailList
                      }
                      updateProfileState={saveDetailHandler}
                      isProfileDataLoading={profileData.isLoading}
                      onAccordianExpansionToggle={accoridanToggleHandler}
                    />
                  )}
                  {actPreferenceData && activity.data && (
                    <ProfileAccordian
                      key={profileHeaders.activitypreference}
                      detailTitle={profileHeaders.activitypreference}
                      profileType="activitypreference"
                      displayValues={actPreferenceData}
                      profileDetailsData={
                        profileData?.data?.find(
                          (item) => item.type === 'activitypreference'
                        )?.profileTypeDetailList
                      }
                      activityFeqData={activity.data}
                      isActivityDataLoading={activity.isLoading}
                      updateProfileState={saveDetailHandler}
                      saveActivityFrequencyData={saveActivityFrequencyData}
                      onAccordianExpansionToggle={accoridanToggleHandler}
                    />
                  )}
                  {accountPrefData && (
                    <ProfileAccordian
                      key={profileHeaders.accountPreference}
                      detailTitle={profileHeaders.accountPreference}
                      profileType="accountpreferences"
                      displayValues={accountPrefData}
                      profileDetailsData={
                        profileData?.data?.find(
                          (item) => item.type === 'accountpreferences'
                        )?.profileTypeDetailList
                      }
                      updateProfileState={saveDetailHandler}
                      isProfileDataLoading={profileData.isLoading}
                      onAccordianExpansionToggle={accoridanToggleHandler}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          ) : (
            <CircularLoader size="medium" />
          )}
        </Grid>
      </div>
      <hr className={classes.lowerBorderLine} />
      {unsubscribeWrapper}
      {width > 600 && <HomeFooter />}
    </>
  );
};

UserProfile.defaultProps = {};

UserProfile.propTypes = {};

export default UserProfile;
