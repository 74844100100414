import { useState } from 'react';

type SnackbarState = {
  open: boolean;
  message: string;
};

const initialState: SnackbarState = {
  open: false,
  message: ''
};

const useSnackbar = (state: SnackbarState = initialState) => {
  const [snackbarState, setSnackbarState] = useState(state);

  const openSnackbar = (message: string) => {
    if (!message) return;
    setSnackbarState({ open: true, message });
  };

  const closeSnackbar = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  return { openSnackbar, closeSnackbar, snackbarState };
};

export default useSnackbar;
