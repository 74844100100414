import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Body,
  Button,
  Grid,
  Modal,
  Spinner,
  TextArea
} from '@walmart-web/livingdesign-components';
import escape from 'validator/lib/escape';
import { Snackbar } from '@material-ui/core';

import styles from './styles.module.css';
import ApiPath from '../../../../constants/api';
import API, {
  API_STATUS_CODE,
  AUTO_HIDE_DURATION
} from '../../../../constants/common';
import apiCall from '../../../../utils/api.js';
import { removeLoginTokens } from '../../../../utilities/utils.js';
import { logger } from '../../../../utils/logger.js';
import useSnackbar from '../../../../hooks/useSnackbar.ts';

type Props = {
  open: boolean;
  handleClose: () => void;
  handleDeleteSuccess: () => void;
};

const DeleteFeedbackModal: React.FC<Props> = ({
  open,
  handleClose,
  handleDeleteSuccess
}) => {
  const [loading, setLoading] = useState(false);
  const { snackbarState, openSnackbar, closeSnackbar } = useSnackbar();
  const [text, setText] = useState('');
  const { t } = useTranslation('profile');

  const handleSubmit = () => {
    const panelistId = localStorage.getItem('panelistId') || '';
    logger.clickStream('delete_account_feedback_submit', {
      panelistId,
      feedback: text
    });
    setLoading(true);
    apiCall(ApiPath.unsubscribe, API.METHOD.POST, {
      feedback: escape(text)
    }).then((response) => {
      setLoading(false);
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        logger.info('Successfully unsubscribed', { panelistId });
        removeLoginTokens();
        handleDeleteSuccess();
      } else {
        logger.error('Error while unsubscribing', { error: response.error });
        openSnackbar(t('deleteAccount.feedbackModal.failedToDelete'));
      }
    });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarState.open}
        message={snackbarState.message}
        autoHideDuration={AUTO_HIDE_DURATION}
        onClose={closeSnackbar}
        ContentProps={{
          classes: {
            root: styles.snackbarRoot
          }
        }}
      />
      <Modal
        isOpen={open}
        onClose={handleClose}
        title={t('deleteAccount.feedbackModal.title')}
        size="medium"
        actions={
          <Grid UNSAFE_className={styles.buttonContainer}>
            <Button
              variant="secondary"
              size="medium"
              onClick={handleClose}
              disabled={loading}
            >
              {t('cancel', { ns: 'common' })}
            </Button>
            <Button
              variant="primary"
              size="medium"
              onClick={handleSubmit}
              disabled={loading || !text}
              UNSAFE_className={styles.submitButton}
            >
              {loading ? (
                <Spinner size="small" />
              ) : (
                t('submit', { ns: 'common' })
              )}
            </Button>
          </Grid>
        }
      >
        <Grid>
          <Body size="large" weight={700} UNSAFE_className={styles.subtitle}>
            {t('deleteAccount.feedbackModal.subtitle')}
          </Body>
          <Body size="large" UNSAFE_className={styles.description}>
            {t('deleteAccount.feedbackModal.description')}
          </Body>
          <TextArea
            label={t('deleteAccount.feedbackModal.inputLabel')}
            onChange={(event) => setText(event.target.value)}
            value={text}
            maxLength={1000}
            aria-required="true"
            placeholder={
              t('deleteAccount.feedbackModal.inputPlaceholder') as string
            }
            className={styles.feedbackTextarea}
          />
        </Grid>
      </Modal>
    </>
  );
};

export default DeleteFeedbackModal;
