import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Body, Button, Grid } from '@walmart-web/livingdesign-components';
import { ChevronLeft as ChevronLeftIcon } from '@livingdesign/icons';

import styles from './styles.module.css';

import useModal from '../../../hooks/useModal.ts';
import DeleteFeedbackModal from './DeleteFeedbackModal/index.tsx';
import DeleteSuccessModal from './DeleteSuccessModal/index.tsx';
import CSBreadcrumbs from '../../../components/CSBreadcrumbs/index.js';
import HomeFooter from '../../../components/Footer/HomeFooter/index.js';
import { useWindowSize } from '../../../hooks/index.js';
import analytics from '../../../utils/analytics.js';
import { PAGES } from '../../../constants/analytics.js';
import { getQueryStringValue, isEmptyString } from '../../../utils/common.js';

const DeleteProfile = () => {
  const history = useHistory();
  const location = useLocation();
  const { width } = useWindowSize();
  const { isOpen, openModal, closeModal } = useModal();
  const { isOpen: isSuccessOpen, openModal: openSuccessModal } = useModal();
  const { t } = useTranslation('profile');

  const flow = getQueryStringValue(location, 'flow') || '';

  useEffect(() => {
    analytics.trackPageView(PAGES.UNSUBSCRIBE);

    if (!isEmptyString(flow) && flow === 'email') {
      history.push('/login', 'unsubEvent');
    }
  }, []);

  const handleDeleteSuccess = () => {
    closeModal();
    openSuccessModal();
  };

  const handleBackClick = () => {
    history.push('/profile');
  };

  const titleIcon = (
    <div className={styles.backBtn}>
      <ChevronLeftIcon size="medium" />
    </div>
  );

  const breadcrumbs = [
    {
      titleText: t('title'),
      titleIcon,
      onClickAction: handleBackClick
    }
  ];

  return (
    <>
      <div className={styles.rootContainer}>
        <CSBreadcrumbs breadcrumbs={breadcrumbs} />
        <Grid UNSAFE_className={styles.deleteContainer}>
          <Body size="large" weight={700}>
            {t('deleteAccount.title')}
          </Body>
          <Body size="large" weight={700}>
            {t('deleteAccount.subtitle')}
          </Body>
          <Body size="large">{t('deleteAccount.description')}</Body>
          <Grid UNSAFE_className={styles.buttonContainer}>
            <Link to="/profile">
              <Button variant="secondary" size="medium">
                {t('cancel', { ns: 'common' })}
              </Button>
            </Link>
            <Button variant="destructive" size="medium" onClick={openModal}>
              {t('deleteAccount.btnText')}
            </Button>
          </Grid>
        </Grid>
        {width! > 600 && <HomeFooter />}
      </div>

      <DeleteFeedbackModal
        open={isOpen}
        handleClose={closeModal}
        handleDeleteSuccess={handleDeleteSuccess}
      />
      <DeleteSuccessModal open={isSuccessOpen} />
    </>
  );
};

export default DeleteProfile;
