import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { PAGES } from '../../constants/analytics';
import analytics from '../../utils/analytics';
import { getQueryStringValue } from '../../utils/common';

const UnsubRoute = () => {
  const location = useLocation();
  const flow = getQueryStringValue(location, 'flow') || '';

  useEffect(() => {
    analytics.trackPageView(PAGES.UNSUBSCRIBE);
  }, []);

  if (flow === 'email')
    return <Redirect to={{ pathname: '/login', state: 'unsubEvent' }} />;

  return <Redirect to="/profile/delete" />;
};

export default UnsubRoute;
