import React from 'react';
import {
  Body,
  Button,
  Grid,
  Modal
} from '@walmart-web/livingdesign-components';
import { Link } from 'react-router-dom';

import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
};

const DeleteSuccessModal: React.FC<Props> = ({ open }) => {
  const { t } = useTranslation('profile');

  return (
    <Modal
      title=" "
      size="medium"
      closeButtonProps={{ UNSAFE_className: styles.closeBtn }}
      isOpen={open}
      onClose={() => {}}
      actions={
        <Link to="/">
          <Button variant="primary" size="medium">
            {t('okay', { ns: 'common' })}
          </Button>
        </Link>
      }
    >
      <Grid>
        <Body size="large" weight={700} UNSAFE_className={styles.subtitle}>
          {t('deleteAccount.successModal.subtitle')}
        </Body>
        <Body size="large" UNSAFE_className={styles.description}>
          {t('deleteAccount.successModal.description')}
        </Body>
      </Grid>
    </Modal>
  );
};

export default DeleteSuccessModal;
